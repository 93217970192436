// Generated by Framer (fb1f9a0)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["vF_bHOv_z"];

const variantClassNames = {vF_bHOv_z: "framer-v-1wwef7p"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; processPhoto1?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "vF_bHOv_z", processPhoto1: NjYKvyU9v, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "vF_bHOv_z", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-yjgqV", classNames)} style={{display: "contents"}}>
<Image {...restProps} background={{alt: "", fit: "fill", ...toResponsiveImage(NjYKvyU9v)}} className={cx("framer-1wwef7p", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"vF_bHOv_z"} ref={ref} style={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, ...style}} transition={transition}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-yjgqV [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-yjgqV * { box-sizing: border-box; }", ".framer-yjgqV .framer-1qvecma { display: block; }", ".framer-yjgqV .framer-1wwef7p { height: 300px; position: relative; width: 600px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 300
 * @framerIntrinsicWidth 600
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"NjYKvyU9v":"processPhoto1"}
 */
const FramerdHswTBwGS: React.ComponentType<Props> = withCSS(Component, css, "framer-yjgqV") as typeof Component;
export default FramerdHswTBwGS;

FramerdHswTBwGS.displayName = "Process Photo 1";

FramerdHswTBwGS.defaultProps = {height: 300, width: 600};

addPropertyControls(FramerdHswTBwGS, {NjYKvyU9v: {title: "Process Photo 1", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerdHswTBwGS, [])